<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <b-loading
        :is-full-page="true"
        :active="isLoading"
      />
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Clienti"
        icon="account-multiple  "
      >
        <refresh-button slot="button" />
        <card-toolbar slot="toolbar">
          <div
            slot="left"
            class="buttons has-addons"
          >
            <router-link
              :to="{name:'client.new'}"
              class="button is-primary"
            >
              Client nou
            </router-link>
          </div>
        </card-toolbar>
        <clients-table-sample
          v-if="clients.length > 0"
          :clients="clients"
          @handleDeleteCustomer="handleDeleteCustomer"
        />
        <clients-table-sample
          v-else
          is-empty
        />
      </card-component>
    </section>
  </div>
</template>

<script>
import { useStore } from '@/store'
import { computed, reactive } from '@vue/composition-api'
import ClientsTableSample from '@/components/ClientsTableSample.vue'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/TitleBar.vue'
import RefreshButton from '@/components/RefreshButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import ClientService from '@/services/clients.service'

export default {
  name: 'Clients',
  components: { CardToolbar, RefreshButton, TitleBar, CardComponent, ClientsTableSample },
  setup (props, { root: { $buefy } }) {
    const titleStack = ['Linguapro', 'Clienti']

    const store = useStore()

    const isDarkModeActive = computed(() => store.state.isDarkModeActive)

    const actionSample = () => {
      $buefy.toast.open({
        message: 'Some action',
        type: 'is-info',
        queue: false
      })
    }

    return {
      titleStack,
      isDarkModeActive,
      actionSample,
      clients: reactive([]),
      isLoading: false
    }
  },
  async created () {
    this.isLoading = true
    this.getAllClients().then((response) => {
      this.clients = response
      this.isLoading = false
    })
  },
  methods: {
    async getAllClients () {
      const { getClients } = ClientService
      const response = await getClients()
      const { data } = response
      return data
    },
    async handleDeleteCustomer (id) {
      const { deleteCustomer } = ClientService
      const response = await deleteCustomer(id)
      const { status } = response
      if (status === 200) {
        const index = this.clients.findIndex((prop) => prop.id === id)
        this.clients.splice(index, 1)
        this.$buefy.snackbar.open({
          message: 'Inregistrea a fost stearsa.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la stergerea inregistrarii.',
          queue: false
        })
      }
    }
  }
}
</script>
