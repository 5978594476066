<template>
  <section class="attendance section is-main-section">
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <card-component
      title="Rapoarte"
      icon="table"
    >
      <div
        class="columns"
      >
        <div class="column">
          <b-field label="Perioada">
            <b-datepicker
              v-model="reportDate"
              type="month"
              placeholder="Selectează..."
              locale="ro"
              :unselectable-dates="unselectableDates"
              @input="handleDateSelect"
            />
          </b-field>
        </div>
      </div>
      <div
        class="columns"
      >
        <div class="column">
          <p
            v-if="customer"
            class="content"
          >
            Client: <b> {{ customer.name }}</b>
          </p>
          <span v-else>-</span>
          <p
            v-if="reportDate === null"
            class="content"
          >
            Perioada: -
          </p>
          <p
            v-else
            class="content"
          >
            Perioada: <b>{{ reportDate | formatDateMonth }} </b>
          </p>
        </div>
      </div>
      <div
        class="columns"
      >
        <div class="column">
          <button
            class="button is-primary"
            @click="downloadCustomerReport()"
          >
            Descarca raport
          </button>
        </div>
      </div>
    </card-component>
  </section>
</template>

<script>
import { computed, ref, watch, reactive } from '@vue/composition-api'

import CardComponent from '@/components/CardComponent.vue'
import ClientService from '@/services/clients.service'
import * as moment from 'moment/moment'

export default {
  name: 'ClientReports',
  components: { CardComponent },
  props: {
    customerId: {
      type: [String, Number],
      default: null,
      required: true
    }
  },
  setup (props, { root: { $buefy } }) {
    const ifCustomerExists = ref(false)
    const clientId = computed(() => props.customerId)
    const customer = reactive({
      id: null,
      name: null,
      email: null
    })
    watch(clientId, newValue => {
      ifCustomerExists.value = false

      if (!newValue) {
        customer.id = null
        customer.name = null
      } else {
        getData()
      }
    })

    const getData = async () => {
      if (clientId.value) {
        const { getCustomer } = ClientService
        const response = await getCustomer(clientId.value)
        if (response && response.data) {
          ifCustomerExists.value = true
          const existingClient = response.data
          customer.id = existingClient.id
          customer.name = existingClient.name
          customer.email = existingClient.email
        }
      }
    }

    getData()

    const formCustomer = computed(() => customer)

    return {
      customer: formCustomer
    }
  },
  data () {
    return {
      reportDate: new Date(),
      isLoading: false
    }
  },
  methods: {
    unselectableDates (date) {
      return date >= new Date()
    },
    handleDateSelect (selectedDate) {
      // Modify the selected date to the last day of the month
      const lastDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)
      this.reportDate = lastDayOfMonth
      this.reportDate = moment(this.reportDate).utc().format()
    },
    async downloadCustomerReport () {
      try {
        this.isLoading = true
        const { getCustomerInvoiceReport } = ClientService
        await getCustomerInvoiceReport(this.customer.id, this.customer.name, this.reportDate)
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        this.$buefy.snackbar.open({
          message: 'Eroare la generararea raportului.',
          queue: false
        })
      }
    }
  }
}
</script>
