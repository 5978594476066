<template>
  <section class="attendance section is-main-section">
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <card-component
      title="Rapoarte"
      icon="table"
    >
      <div class="columns">
        <div class="column">
          <b-field label="Client">
            <b-autocomplete
              ref="searchedCity"
              v-model="searchedCity"
              field="name"
              rounded
              :data="filteredCities"
              placeholder="Caută un client"
              icon="account-search"
              clearable
              :disabled="!clients"
              @select="(option) => showReport(option)"
            >
              <template slot="empty">
                Nu a fost găsit nici un rezultat.
              </template>
            </b-autocomplete>
          </b-field>
        </div>
      </div>
      <div
        class="columns"
      >
        <div class="column">
          <b-field label="Perioada">
            <b-datepicker
              v-model="reportDate"
              type="month"
              placeholder="Selectează..."
              locale="ro"
              :unselectable-dates="unselectableDates"
              @input="handleDateSelect"
            />
          </b-field>
        </div>
      </div>
      <div
        class="columns"
      >
        <div class="column">
          <p class="content">
            Client: <b> {{ !selected ? '-' : selected.name }}</b>
          </p>
          <p
            v-if="reportDate === null"
            class="content"
          >
            Perioada: -
          </p>
          <p
            v-else
            class="content"
          >
            Perioada: <b>{{ reportDate | formatDateMonth }} </b>
          </p>
        </div>
      </div>
      <div
        class="columns"
      >
        <div class="column">
          <b-dropdown aria-role="list">
            <template #trigger="{ active }">
              <b-button
                label="Descarca raport"
                type="is-primary"
                :icon-right="active ? 'menu-up' : 'menu-down'"
              />
            </template>
            <b-dropdown-item
              aria-role="listitem"
              @click="downloadCustomerReport()"
            >
              Raport factura
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </card-component>
  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'
import ClientService from '@/services/clients.service'
import * as moment from 'moment/moment'

export default {
  name: 'Reports',
  components: { CardComponent },
  data () {
    return {
      filteredCities: [],
      selected: null,
      searchedCity: '',
      clients: [],
      isScrollable: false,
      maxHeight: 200,
      currentMenu: { text: 'Factura' },
      reportDate: new Date(),
      isLoading: false
    }
  },
  watch: {
    searchedCity: {
      handler: function (val, oldVal) {
        this.filteredCities = []
        if (this.clients) {
          this.filteredCities = this.clients.filter(
            (option) =>
              option.name
                .toString()
                .toLowerCase()
                .includes(this.searchedCity.toLowerCase()) && option.name
          )
        }
        this.filteredCities = this.filteredCities.sort(function (a, b) {
          var textA = a.name.toUpperCase()
          var textB = b.name.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
      },
      immediate: true
    }
  },
  async created () {
    this.getClients().then((response) => {
      this.clients = response
    })
  },
  methods: {
    unselectableDates (date) {
      return date >= new Date()
    },
    handleDateSelect (selectedDate) {
      // Modify the selected date to the last day of the month
      const lastDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)
      this.reportDate = lastDayOfMonth
      this.reportDate = moment(this.reportDate).utc().format()

      // this.reportDate = JSON.stringify(selectedDate.toString())
      // console.log(new Date(
      //   this.reportDate.date.getTime() -
      //           this.reportDate.date.getTimezoneOffset() * 60000
      // ).toJSON())

      // console.log(this.reportDate)
      // console.log(typeof this.reportDate)
      // this.reportDate = selectedDate
      // const repDate = new Date(selectedDate)
      // console.log(this.reportDate)
      // this.reportDate = new Date(
      //   repDate.getTime() -
      //            repDate.getTimezoneOffset() * 60000
      // ).toJSON()
    },
    async showReport (selectedClient) {
      this.selected = selectedClient
    },
    async getClients () {
      const { getClients } = ClientService
      const response = await getClients()
      const { data } = response
      return data
    },
    async downloadCustomerReport () {
      this.isLoading = true
      const { getCustomerInvoiceReport } = ClientService
      await getCustomerInvoiceReport(this.selected.id, this.selected.name, this.reportDate)
      this.isLoading = false
    }
  }
}
</script>
