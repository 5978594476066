<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <b-loading
        :is-full-page="true"
        :active="isLoading"
      />
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Grupe"
        icon="account-group"
      >
        <refresh-button slot="button" />
        <card-toolbar slot="toolbar">
          <div
            slot="left"
            class="buttons has-addons"
          >
            <router-link
              :to="{name:'group.new'}"
              class="button is-primary"
            >
              Grupa noua
            </router-link>
          </div>
        </card-toolbar>
        <groups-table-sample
          v-if="groups.length > 0"
          :groups="groups"
          @handleDeleteGroup="handleDeleteGroup"
        />
        <groups-table-sample
          v-else
          is-empty
        />
      </card-component>
    </section>
  </div>
</template>

<script>

import { reactive } from '@vue/composition-api'
import GroupsTableSample from '@/components/GroupsTableSample.vue'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/TitleBar.vue'
import RefreshButton from '@/components/RefreshButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import GroupService from '@/services/groups.service'

export default {
  name: 'Groups',
  components: { CardToolbar, RefreshButton, TitleBar, CardComponent, GroupsTableSample },
  setup (props, { root: { $buefy } }) {
    const titleStack = ['Linguapro', 'Grupe']

    return {
      groups: reactive([]),
      titleStack,
      isLoading: false
    }
  },
  async created () {
    this.isLoading = true
    this.getAllGroups().then((response) => {
      this.groups = response
      this.isLoading = false
    })
  },
  methods: {
    async getAllGroups () {
      const { getGroups } = GroupService
      const response = await getGroups()
      const { data } = response
      return data
    },
    async handleDeleteGroup (id) {
      const { deleteGroup } = GroupService
      const response = await deleteGroup(id)
      const { status } = response
      if (status === 200) {
        const index = this.groups.findIndex((prop) => prop.id === id)
        this.groups.splice(index, 1)
        this.$buefy.snackbar.open({
          message: 'Inregistrea a fost stearsa.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la stergerea inregistrarii.',
          queue: false
        })
      }
    }
  }
}
</script>
